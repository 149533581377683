type ApiCall<S> = (callback: (result: Office.AsyncResult<S>) => void) => void;

const promisify = <S>(apiCall: ApiCall<S>): Promise<S> => {
  return new Promise((res, rej) => {
    apiCall((result) =>
      result.status === Office.AsyncResultStatus.Succeeded ? res(result.value) : rej(result.error)
    );
  });
};

/**
 * Since changeset 1.9 the mailbox item can be undefined. This function asserts that it is defined.
 * @returns mailbox item
 */
export const mailboxItem = (): Office.Appointment &
  Office.AppointmentCompose &
  Office.AppointmentRead &
  Office.Item &
  Office.ItemCompose &
  Office.ItemRead &
  Office.Message &
  Office.MessageCompose &
  Office.MessageRead => {
  if (!Office.context.mailbox.item) {
    throw new Error('Office mailbox item not accessible');
  }

  return Office.context.mailbox.item;
};

export const loadCustomProperties = (): Promise<Office.CustomProperties> => {
  return promisify((callback) => mailboxItem().loadCustomPropertiesAsync(callback));
};

export const saveCustomProperties = (customProperties: Office.CustomProperties): Promise<void> => {
  return promisify((callback) => customProperties.saveAsync(callback));
};

export const getRequiredAttendees = (): Promise<Office.EmailAddressDetails[]> => {
  return promisify((callback) => mailboxItem().requiredAttendees.getAsync({}, callback));
};

export const getOptionalAttendees = (): Promise<Office.EmailAddressDetails[]> => {
  return promisify((callback) => mailboxItem().optionalAttendees.getAsync({}, callback));
};

export const getStartTime = (): Promise<Date> => {
  return promisify((callback) => mailboxItem().start.getAsync({}, callback));
};

export const getAllAttendees = async (): Promise<Office.EmailAddressDetails[]> => {
  const [requiredAttendees, optionalAttendees] = await Promise.all([
    getRequiredAttendees(),
    getOptionalAttendees(),
  ]);

  return [...requiredAttendees, ...optionalAttendees];
};

export const getHtmlBody = (): Promise<string> => {
  return promisify((callback) =>
    mailboxItem().body.getAsync(Office.CoercionType.Html, {}, callback)
  );
};

export const setHtmlBody = (body: string): Promise<void> => {
  return promisify((callback) =>
    mailboxItem().body.setAsync(
      body,
      {
        coercionType: Office.CoercionType.Html,
      },
      callback
    )
  );
};

export const extractIdFromBody = async (): Promise<string | undefined> => {
  const body = await getHtmlBody();

  // matches uuid. [\r\n ] matches newline (windows, macos) or space (web)
  const MATCH_REGEX =
    /LEGO Meeting ID:[\r\n ]+(?<id>[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/gsu;

  const match = MATCH_REGEX.exec(body);

  return match?.groups?.id;
};

export const embedIdInBody = async (id: string): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log('Embedding id in body', id);
  let body = await getHtmlBody();

  // Remove the weird empty paragraphs that office adds
  body = body.replaceAll(/<p class=MsoNormal>&nbsp;<\/p>\n*/gsu, '');

  // If already in body change one-liner
  if (body.includes('LEGO Meeting ID:')) {
    const MATCH_REGEX =
      /LEGO Meeting ID:[\r\n ]+(?<id>[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})\s?\d*/gsu;

    // we add a unique string to the end in order to force EWS to trigger webhook event when updating events
    body = body.replace(MATCH_REGEX, `LEGO Meeting ID: ${id} ${new Date().getTime()}`);
  }
  // If not in body add section to the end
  else {
    const embeddedHtml = `<span><br><br><b>! DO NOT DELETE THIS LINE !</b> LEGO Meeting ID: ${id}<br></span>`;

    if (/<\/body>.*<\/html>/gsu.test(body)) {
      // Embed the string and set the new body
      body = body.replace(/<\/body>.*<\/html>/gsu, `${embeddedHtml}</body></html>`);
    } else {
      body += embeddedHtml;
    }
  }

  await setHtmlBody(body);
};

export const removeIdFromBody = async (): Promise<void> => {
  let body = await getHtmlBody();

  body = body.replace(
    /<span>\s*<br>\s*<br>\s*<b>\s*[!A-Z ]+\s*<\/b>\s*LEGO Meeting ID: [a-zA-Z0-9]+\s*<br>\s*<\/span>/gsu,
    ''
  );

  await setHtmlBody(body);
};

const LEGO_VMS_DISPLAY_NAME = 'LEGO visitor management';

export const findVMSRecipient = async (): Promise<string | undefined> => {
  const attendees = await getOptionalAttendees();

  return attendees.find((x) => x.displayName === LEGO_VMS_DISPLAY_NAME)?.emailAddress;
};

export const updateVMSAttendee = async (email: string): Promise<void> => {
  const attendees = await getOptionalAttendees();

  // We already have the address included
  if (attendees.some((a) => a.emailAddress === email)) {
    return;
  }

  // Remove vms attendee by name
  const newAttendees: Array<Office.EmailAddressDetails | Office.EmailUser> = attendees.filter(
    (a) => a.displayName !== LEGO_VMS_DISPLAY_NAME
  );

  // Add vms attendee
  newAttendees.push({
    emailAddress: email,
    displayName: LEGO_VMS_DISPLAY_NAME,
  });

  // Update optional attendees list
  await promisify((callback) =>
    mailboxItem().optionalAttendees.setAsync(newAttendees, {}, callback)
  );
};

export const getItemId = async (): Promise<string | undefined> => {
  try {
    const itemId = await promisify(mailboxItem().getItemIdAsync);

    return Office.context.mailbox.convertToRestId(itemId, Office.MailboxEnums.RestVersion.v2_0);
  } catch (e) {
    console.error(e);

    return undefined;
  }
};

export const getAccessToken = (): Promise<string> =>
  OfficeRuntime.auth.getAccessToken({
    // Generate a identity token and store it in app state
    // Should only be set when actually run from AD
    // Will not work for local development where the addin is added manually
    forMSGraphAccess: import.meta.env.NODE_ENV === 'production',
  });
